import parse from 'html-react-parser';
import React from 'react';
import { Helmet } from 'react-helmet';
import ScrollspyNav from 'react-scrollspy-nav';
import CopyRightThree from '../components/CopyRightThree';
import HeaderLandingMobileApp from '../components/HeaderLandingMobileApp';

const TermsConditionsContent = () => {
  return parse(`
  <p>
By downloading or using the Smarnat mobile app ("App"), these terms and conditions ("Terms") will automatically apply to you. Please read these Terms carefully before using the App. You are not allowed to copy, modify, or infringe upon our trademarks or attempt to extract the source code of the App. The App and all associated intellectual property rights belong to tarikfp.
</p>

<p>
tarikfp is committed to ensuring that the App is as useful and efficient as possible. For that reason, we reserve the right to make changes to the App or charge for its services at any time and for any reason. We will always make it clear to you what you are paying for.
</p>

<p>
<b>In-App Subscriptions</b>
</p>

<p>
Our Smarnat app may offer in-app subscriptions that provide additional features or content. When you subscribe to any of these services, your payment information, such as your credit card details, billing address, and other financial information, is collected and processed by the app store or platform through which you make the purchase (e.g., Apple App Store, Google Play Store).
</p>
<p>
We do not have access to or store your payment information. The processing of payments and management of subscriptions are handled by the app store's or platform's own privacy policies and terms. We encourage you to review the privacy policies of the respective app store or platform for more information on how your payment data is collected and processed.
</p>
<p>
Please note that the specific features and terms of your in-app subscription will be provided to you within the app. You can manage your subscription, including canceling it, by visiting the app store or platform where you made the purchase.
</p>

<p>
By subscribing to these services, you agree to the following terms:
</p>

<ul>
  <li>Subscription Plans: The App offers various subscription plans, each with its own pricing, duration, and features. You can review the details of each plan within the App before subscribing.</li>
  <li>Payment: When you subscribe, you will be charged according to the selected subscription plan. Payment will be processed through your app store account (e.g., Google Play or Apple App Store).</li>
  <li>Auto-Renewal: Subscriptions may auto-renew unless you cancel them. You can manage your subscription and auto-renewal settings through your app store account.</li>
  <li>Cancellation: You can cancel your subscription at any time through your app store account. Refunds are subject to the refund policies of the app store.</li>
  <li>Changes: tarikfp reserves the right to change the subscription plans, pricing, and features. Any changes will be communicated within the App.</li>
</ul>


<p>
If you have any questions or concerns about your in-app subscription, please contact the app store or platform directly. We do not have access to your subscription or payment information.
</p>




<div>
  <p>
    The App uses third-party services, each with their own Terms and Conditions. You can find links to the Terms and Conditions of the third-party service providers we use below:
  </p>
  <ul>
    <li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
    <li><a href="https://onesignal.com/tos" target="_blank" rel="noopener noreferrer">One Signal</a></li>
    <li><a href="https://expo.io/terms" target="_blank" rel="noopener noreferrer">Expo</a></li>
    <li><a href="https://sentry.io/terms/" target="_blank" rel="noopener noreferrer">Sentry</a></li>
    <li><a href="https://www.revenuecat.com/terms" target="_blank" rel="noopener noreferrer">RevenueCat</a></li>
  </ul>
</div>

<p>
Please be aware that certain features of the App may require an active internet connection. The App may use Wi-Fi or your mobile network provider's data connection. We cannot be held responsible if the App does not function at full capacity due to the absence of a data connection.
</p>

<p>
If you are using the App outside an area with Wi-Fi, the terms and conditions of your mobile network provider will apply. You may be charged by your mobile provider for data usage or other third-party charges while using the App. By using the App, you accept responsibility for any such charges, including roaming data charges incurred when using the App outside your home territory (i.e., region or country) without disabling data roaming. If you are not the bill payer for the device on which you use the App, you should obtain permission from the bill payer.
</p>

<p>
tarikfp cannot be held responsible for your device's battery life. Ensure your device remains charged, as we cannot accept responsibility if it runs out of battery, rendering you unable to use the Service.
</p>

<p>
Regarding tarikfp's responsibility for your use of the App, please be aware that while we strive to keep the App updated and accurate, we rely on third-party data sources for information. tarikfp accepts no liability for any direct or indirect losses resulting from relying solely on the App's functionality.
</p>

<p>
We may update the App from time to time. The App is available on Android and iOS, and the requirements for both systems (and for any additional systems to which we extend availability) may change. You must download updates to continue using the App. We do not guarantee continuous updates to maintain compatibility with the specific version of Android or iOS on your device. We may also discontinue the App or terminate its use at any time without notice. Unless stated otherwise, upon termination, (a) your rights and licenses granted under these Terms will cease; (b) you must cease using the App and, if necessary, delete it from your device.
</p>

<p><strong>Changes to These Terms and Conditions</strong></p>

<p>
We may update these Terms from time to time. You are advised to periodically review this page for any changes. We will notify you of any changes by posting the updated Terms on this page.
</p>

<p><strong>Contact Us</strong></p>

<p>
If you have any questions or suggestions regarding these Terms and Conditions, please do not hesitate to contact us at smarnatapp@gmail.com.
</p>

    `);
};

const PrivacyPolicyContent = () => {
  return parse(`
  <p>
tarikfp built the Smarnat app as
a Free app. This SERVICE is provided by
tarikfp at no cost and is intended for use as
is.
</p> <p>
This page is used to inform visitors regarding my
policies with the collection, use, and disclosure of Personal
Information if anyone decided to use my Service.
</p> <p>
If you choose to use my Service, then you agree to
the collection and use of information in relation to this
policy. The Personal Information that I collect is
used for providing and improving the Service. I will not use or share your information with
anyone except as described in this Privacy Policy.
</p> <p>
The terms used in this Privacy Policy have the same meanings
as in our Terms and Conditions, which are accessible at
Smarnat unless otherwise defined in this Privacy Policy.
</p> <p><strong>Information Collection and Use</strong></p> <p>
For a better experience, while using our Service, I
may require you to provide us with certain personally
identifiable information. The information that
I request will be retained on your device and is not collected by me in any way.
</p> <div><p>
The app does use third-party services that may collect
information used to identify you.
</p> <p>
Link to the privacy policy of third-party service providers used
by the app
</p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><li><a href="https://onesignal.com/privacy_policy" target="_blank" rel="noopener noreferrer">One Signal</a></li><li><a href="https://expo.io/privacy" target="_blank" rel="noopener noreferrer">Expo</a></li><li><a href="https://sentry.io/privacy/" target="_blank" rel="noopener noreferrer">Sentry</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><li><a href="https://www.revenuecat.com/privacy" target="_blank" rel="noopener noreferrer">RevenueCat</a></li><!----></ul></div> <p><strong>Log Data</strong></p> <p>
I want to inform you that whenever you
use my Service, in a case of an error in the app
I collect data and information (through third-party
products) on your phone called Log Data. This Log Data may
include information such as your device Internet Protocol
(“IP”) address, device name, operating system version, the
configuration of the app when utilizing my Service,
the time and date of your use of the Service, and other
statistics.
</p> <p><strong>Cookies</strong></p> <p>
Cookies are files with a small amount of data that are
commonly used as anonymous unique identifiers. These are sent
to your browser from the websites that you visit and are
stored on your device's internal memory.
</p> <p>
This Service does not use these “cookies” explicitly. However,
the app may use third-party code and libraries that use
“cookies” to collect information and improve their services.
You have the option to either accept or refuse these cookies
and know when a cookie is being sent to your device. If you
choose to refuse our cookies, you may not be able to use some
portions of this Service.

<p><strong>In-App Subscriptions</strong></p>
<p>
Our Smarnat app may offer in-app subscriptions that provide additional features or content. When you subscribe to any of these services, your payment information, such as your credit card details, billing address, and other financial information, is collected and processed by the app store or platform through which you make the purchase (e.g., Apple App Store, Google Play Store).
</p>
<p>
We do not have access to or store your payment information. The processing of payments and management of subscriptions are handled by the app store's or platform's own privacy policies and terms. We encourage you to review the privacy policies of the respective app store or platform for more information on how your payment data is collected and processed.
</p>
<p>
Please note that the specific features and terms of your in-app subscription will be provided to you within the app. You can manage your subscription, including canceling it, by visiting the app store or platform where you made the purchase.
</p>
<p>
If you have any questions or concerns about your in-app subscription, please contact the app store or platform directly. We do not have access to your subscription or payment information.
</p>

</p> <p><strong>Service Providers</strong></p> <p>
I may employ third-party companies and
individuals due to the following reasons:
</p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
I want to inform users of this Service
that these third parties have access to their Personal
Information. The reason is to perform the tasks assigned to
them on our behalf. However, they are obligated not to
disclose or use the information for any other purpose.
</p> <p><strong>Security</strong></p> <p>
I value your trust in providing us your
Personal Information, thus we are striving to use commercially
acceptable means of protecting it. But remember that no method
of transmission over the internet, or method of electronic
storage is 100% secure and reliable, and I cannot
guarantee its absolute security.
</p> <p><strong>Links to Other Sites</strong></p> <p>
This Service may contain links to other sites. If you click on
a third-party link, you will be directed to that site. Note
that these external sites are not operated by me.
Therefore, I strongly advise you to review the
Privacy Policy of these websites. I have
no control over and assume no responsibility for the content,
privacy policies, or practices of any third-party sites or
services.
</p> <p><strong>Children’s Privacy</strong></p> <div><p>
These Services do not address anyone under the age of 13.
I do not knowingly collect personally
identifiable information from children under 13 years of age. In the case
I discover that a child under 13 has provided
me with personal information, I immediately
delete this from our servers. If you are a parent or guardian
and you are aware that your child has provided us with
personal information, please contact me so that
I will be able to do the necessary actions.
</p></div> <!----> <p><strong>Changes to This Privacy Policy</strong></p> <p>
I may update our Privacy Policy from
time to time. Thus, you are advised to review this page
periodically for any changes. I will
notify you of any changes by posting the new Privacy Policy on
this page.
</p> <p><strong>Contact Us</strong></p> <p>
If you have any questions or suggestions about my
Privacy Policy, do not hesitate to contact me at smarnatapp@gmail.com.
</p>

    `);
};

const TermsConditions = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>Terms & Conditions</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLandingMobileApp />
      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={['termsconditions', 'privacypolicy']}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      href="#termsconditions"
                    >
                      1. Terms and conditions
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#privacypolicy">
                      2. Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content ">
                  <div id="termsconditions">
                    <h2 className="font-gilroy-bold">
                      Terms and Conditions
                    </h2>
                    <div className="update-date">
                      LAST UPDATED: 15/10/2023
                    </div>
                    <TermsConditionsContent />
                  </div>

                  <div id="privacypolicy">
                    <h2 className="font-gilroy-bold">
                      Privacy Policy
                    </h2>
                    {/* <TermsConditionsContent /> */}
                    <div className="update-date">
                      LAST UPDATED: 15/10/2023
                    </div>
                    <PrivacyPolicyContent />
                  </div>
                </div>
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>

      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightThree />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TermsConditions;

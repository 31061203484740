import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ScrollTopBehaviour from '../components/ScrollTopBehaviour';
import ContactCustomerSupport from '../views/ContactCustomerSupport';
import MobileAppLanding from '../views/MobileAppLanding';
import NotFound from '../views/NotFound';
import TermsConditions from '../views/TermsConditions';

const AllRoutes = () => {
  return (
    <>
      <ScrollTopBehaviour />
      <Routes>
        <Route path="/" element={<MobileAppLanding />} />
        <Route
          path="/privacy-and-terms-conditions"
          element={<TermsConditions />}
        />
        <Route
          path="/contact-us"
          element={<ContactCustomerSupport />}
        />

        {/* NotFound Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;

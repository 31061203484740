import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Scrollspy from 'react-scrollspy';

const HeaderLandingMobileApp = () => {
  // For header select menu
  const [click1, setClick1] = useState(false);
  const handleClick1 = () => setClick1(!click1);

  // For Mobile  menu
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const isInMainScreen = window.location.pathname.trim() === '/';

  window.addEventListener('scroll', changeBackground);

  return (
    <>
      {/* =============================================
				Theme Main Menu
			==============================================  */}
      <div
        className={
          navbar
            ? 'theme-main-menu sticky-menu theme-menu-five fixed'
            : 'theme-main-menu sticky-menu theme-menu-five'
        }
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo">
            <Link
              to="/"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }}
              className="nav-link"
            >
              <img
                style={{ width: 50, height: 50 }}
                src="images/assets/smarnat-logo.png"
                alt="brand"
              />
            </Link>
          </div>
          {/* End logo */}

          <nav
            id="mega-menu-holder"
            className="navbar navbar-expand-lg"
          >
            <div className="container nav-container">
              <div className="mob-header">
                <button
                  className="toggler-menu"
                  onClick={handleClick}
                >
                  <div className={click ? 'active' : ''}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>
              {/* End Header */}

              <div
                className="navbar-collapse collapse landing-menu-onepage"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <Scrollspy
                    className="navbar-nav  main-side-nav font-gordita"
                    items={['home', 'product', 'features']}
                    currentClassName="active"
                    offset={-90}
                  >
                    <li className="nav-item dropdown position-static">
                      {isInMainScreen ? (
                        <Link
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              behavior: 'smooth',
                            });
                          }}
                          className="nav-link"
                        >
                          Home
                        </Link>
                      ) : (
                        <Link className="nav-link" to="/#home">
                          Home
                        </Link>
                      )}
                    </li>
                    <li className="nav-item">
                      {isInMainScreen ? (
                        <a href="#product" className="nav-link">
                          Product
                        </a>
                      ) : (
                        <Link className="nav-link" to="/#product">
                          Product
                        </Link>
                      )}
                    </li>
                    <li className="nav-item">
                      {isInMainScreen ? (
                        <a href="#features" className="nav-link">
                          Features
                        </a>
                      ) : (
                        <Link className="nav-link" to="/#features">
                          Features
                        </Link>
                      )}
                    </li>

                    {/*    <li className="nav-item">
                      <a href="#pricing" className="nav-link">
                        Pricing
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#feedback" className="nav-link">
                        Feedback
                      </a>
                    </li> */}
                  </Scrollspy>
                </div>
              </div>
            </div>
          </nav>
          {/* End Navbar */}

          <div className="right-widget">
            <ul className="d-flex align-items-center">
              <li>
                <div
                  className={
                    click1
                      ? 'dropdown download-btn ms-0 style-two show'
                      : 'dropdown download-btn ms-0 style-two'
                  }
                >
                  <button
                    className="dropdown-toggle"
                    onClick={handleClick1}
                    type="button"
                  >
                    Download
                  </button>
                  <div
                    className={
                      click1 ? 'dropdown-menu  show' : 'dropdown-menu'
                    }
                  >
                    <Link
                      onClick={() => {
                        handleClick1();
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        });
                      }}
                      className="dropdown-item d-flex align-items-center"
                      to="/#home"
                    >
                      <img src="images/icon/103.svg" alt="icon" />
                      <span>IOS & Android</span>
                    </Link>
                    {/*   <a
                      className="dropdown-item d-flex align-items-center"
                      href="#"
                      onClick={handleClick1}
                    >
                      <img src="images/icon/104.svg" alt="icon" />
                      <span>Mac & Windows</span>
                    </a> */}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          {/* End right-widget */}
        </div>
      </div>
      {/* /.theme-main-menu */}

      {/* Mobile Menu Start */}
      <div
        className={click ? 'mobile-menu  menu-open' : 'mobile-menu'}
      >
        <div className="logo order-md-1">
          <Link to="/doc-landing">
            <img
              style={{ width: 50, height: 50 }}
              src="images/assets/smarnat-logo.png"
              alt="brand"
            />
          </Link>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="images/icon/close.svg" alt="icon" />
          </div>
          {/* Mobile Menu close icon */}
        </div>

        <Scrollspy
          className="navbar-nav  main-side-nav font-gordita"
          items={['home', 'product', 'features']}
          currentClassName="active"
          offset={-90}
        >
          <li className="nav-item">
            {isInMainScreen ? (
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                }}
                className="nav-link"
              >
                Home
              </Link>
            ) : (
              <Link className="nav-link" to="/#home">
                Home
              </Link>
            )}
          </li>
          <li className="nav-item">
            {isInMainScreen ? (
              <a
                href="#product"
                onClick={handleClick}
                className="nav-link"
              >
                Product
              </a>
            ) : (
              <Link className="nav-link" to="/#product">
                Product
              </Link>
            )}
          </li>
          <li className="nav-item">
            {isInMainScreen ? (
              <a
                href="#features"
                onClick={handleClick}
                className="nav-link"
              >
                Features
              </a>
            ) : (
              <Link className="nav-link" to="/#features">
                Features
              </Link>
            )}
          </li>
        </Scrollspy>
      </div>
      {/* Mobile Menu End */}
    </>
  );
};

export default HeaderLandingMobileApp;

import React from "react";
import { Helmet } from "react-helmet";
import CopyRightThree from "../components/CopyRightThree";
import HeaderLandingMobileApp from "../components/HeaderLandingMobileApp";
import MobileAppPortfolio from "./MobileAppPortfolio";

export const APP_STORE_URL_ANDROID =
  "https://play.google.com/store/apps/details?id=com.tarikfp.chatai";
export const APP_STORE_URL_IOS =
  "https://apps.apple.com/app/smarnat/id6464123145";

const MobileAppLanding = () => {
  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title> Smarnat App</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLandingMobileApp />
      {/* End Mobile App Landing Header */}

      {/* =============================================
            Theme Hero Banner
        ==============================================  */}
      <div className="hero-banner-ten" id="home">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-11 col-md-8 m-auto">
              <h1
                id="home"
                className="hero-heading"
                data-aos="fade-up"
                data-aos-duration="700"
              >
                Smarnat App
              </h1>
              <p
                className="hero-sub-heading"
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-delay="100"
              >
                Elevate your conversations with the brilliance of
                ChatGPT
                <br />
                <br />
                <div style={{ marginTop: 12 }}>
                  Experience the future of communication with our
                  AI-powered chat app
                </div>
              </p>
            </div>
          </div>
          {/* End .row */}

          <div className="d-sm-flex align-items-center justify-content-center button-group">
            <a
              href={APP_STORE_URL_IOS}
              target="_blank"
              className="d-flex align-items-center ios-button"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="200"
              rel="noreferrer"
            >
              <img
                src="images/icon/apple.svg"
                alt="icon"
                className="icon"
              />
              <div>
                <span>Download on the</span>
                <strong>App store</strong>
              </div>
            </a>
            <a
              href={APP_STORE_URL_ANDROID}
              target="_blank"
              className="d-flex align-items-center windows-button"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="200"
              rel="noreferrer"
            >
              <img
                src="images/icon/playstore.svg"
                alt="icon"
                className="icon"
              />
              <div>
                <span>Get it on</span>
                <strong>Google play</strong>
              </div>
            </a>
          </div>
        </div>
      </div>
      {/* =============================================
            App Screen Preview
        ==============================================  */}
      <div id="product" className="app-screen-preview-one">
        <div className="container">
          <div className="app-preview-slider-one arrow-middle-center ">
            <MobileAppPortfolio />
          </div>
        </div>

        <img
          src="images/shape/bg5.svg"
          alt="shape"
          className="shapes round-bg"
        />
      </div>
      <div
        className="fancy-feature-twentyFive lg-container pt-200 md-pt-100"
        id="product"
      >
        <div className="container">
          <div className="block-style-twentyFive">
            <div className="row align-items-center">
              <div
                className="col-xl-7 col-lg-6 col-md-10 m-auto pt-100 sm:pt-50"
                data-aos="fade-right"
                data-aos-duration="700"
              >
                <div className="screen-container">
                  <img
                    style={{
                      maxWidth: "50%",
                      alignSelf: "center",
                      marginLeft: "25%",
                      borderRadius: 999,
                    }}
                    src="images/assets/app-icons/appstore.png"
                    alt="screen"
                  />
                </div>
                {/*  /.screen-container */}
              </div>
              <div
                className="col-xl-5 col-lg-6"
                data-aos="fade-left"
                data-aos-duration="700"
              >
                <div className="text-wrapper ps-xl-5 ">
                  <h3 className="title">What about it?</h3>
                  <p>
                    Experience the future of communication with our
                    app, where limitless messaging meets instant
                    responses and detailed answers. ChatGPT, powering
                    your conversations to a whole new level.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="block-style-twentyFive mt-200 md-mt-100 container"
        id="features"
      >
        <div className="row align-items-center">
          <div
            className="col-xl-7 col-lg-6 col-md-10 m-auto text-center text-lg-right order-lg-last"
            data-aos="fade-left"
            data-aos-duration="700"
          >
            <div className="screen-container">
              <img
                src="images/shape/bg6.svg"
                alt="shape"
                className="ms-auto bg-round-shape"
              />
              <div className="block-content">
                <div className="row align-items-start">
                  {/* End .col */}

                  <div className="col-sm-6">
                    <div className="feature-meta">
                      <div className="icon d-flex align-items-end">
                        <img
                          style={{ width: 75, height: 75 }}
                          src="images/assets/app-icons/camera-logo.png"
                          alt=""
                        />
                      </div>
                      <h4>
                        Image <br />
                        Identification
                      </h4>
                    </div>
                    {/* /.feature-meta */}
                    <div className="feature-meta">
                      <div className="icon d-flex align-items-end">
                        <img
                          style={{ width: 75, height: 75 }}
                          src="images/assets/app-icons/reload.svg"
                          alt=""
                        />
                      </div>
                      <h4>
                        Unlimited <br /> messages
                      </h4>
                    </div>
                    {/* /.feature-meta */}
                  </div>

                  <div className="col-sm-6">
                    <div className="feature-meta">
                      <div className="icon d-flex align-items-end">
                        <img
                          style={{ width: 75, height: 75 }}
                          src="images/assets/app-icons/chatgpt-logo.svg"
                          alt=""
                        />
                      </div>
                      <h4>
                        ChatGPT <br />
                        Powered
                      </h4>
                    </div>
                    {/* /.feature-meta */}
                    <div className="feature-meta">
                      <div className="icon d-flex align-items-end">
                        <img
                          style={{ width: 75, height: 75 }}
                          src="images/assets/app-icons/chat-history.svg"
                          alt=""
                        />
                      </div>
                      <h4>
                        Chat
                        <br />
                        History
                      </h4>
                    </div>
                    {/* /.feature-meta */}
                  </div>
                  {/* End .col */}
                </div>
              </div>
              {/*  /.block-content */}
            </div>
            {/* /.screen-container */}
          </div>
          <div
            className="col-xl-5 col-lg-6 mb-100"
            data-aos="fade-right"
            data-aos-duration="700"
          >
            <div className="text-wrapper order-lg-first">
              <h3 className="title">Why you choose Smarnat app?</h3>
              <p>
                Unleash the power of AI-driven conversations with our
                chat app.
              </p>
              <ul>
                <li>
                  Upload your photo and experience the perfect
                  explanation
                </li>
                <li>
                  Unlimited messages for never-ending conversations
                </li>
                <li>
                  Instant responses that keep your chat flowing
                  seamlessly
                </li>
                <li>
                  Chat history, so you never miss a beat in your
                  conversations
                </li>
                <li>
                  Detailed answers that provide depth and clarity to
                  your discussions
                </li>
              </ul>
            </div>
            {/*  /.text-wrapper */}
          </div>
        </div>
      </div>
      {/*  /.fancy-feature-twentyFive */}

      {/* =====================================================
            Fancy Short Banner Twelve
        ===================================================== */}
      <div className="fancy-short-banner-twelve">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-10 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="700"
            >
              <div className="title-style-ten text-center">
                <h2>Love our Smarnat application? Download now!</h2>
                <p className="pt-25 pb-45">
                  Unlock the potential of AI conversations with our
                  cutting-edge chat app
                </p>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="700"
            data-aos-delay="150"
          >
            <div className="d-sm-flex align-items-center justify-content-center button-group">
              <a
                href={APP_STORE_URL_IOS}
                className="d-flex align-items-center ios-button"
              >
                <img
                  src="images/icon/apple-black.svg"
                  alt="icon"
                  className="icon"
                />
                <div>
                  <span>Download on the</span>
                  <strong>App store</strong>
                </div>
              </a>
              <a
                href={APP_STORE_URL_ANDROID}
                className="d-flex align-items-center windows-button"
              >
                <img
                  src="images/icon/playstore.svg"
                  alt="icon"
                  className="icon"
                />
                <div>
                  <span>Get it on</span>
                  <strong>Google play</strong>
                </div>
              </a>
            </div>
          </div>
        </div>
        {/* /.container */}
        <img
          src="images/shape/220.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/221.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
      {/* /.fancy-short-banner-twelve */}

      {/* =====================================================
				Footer Style Seven
			===================================================== */}
      <footer className="theme-footer-seven md-100">
        <div className="container">
          <div className="bottom-footer">
            <CopyRightThree />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MobileAppLanding;

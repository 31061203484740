import React from 'react';
import { Link } from 'react-router-dom';

const socialContent = [
  {
    icon: 'fa-twitter',
    link: 'https://twitter.com/smarnatai',
  },
  {
    icon: 'fa-instagram',
    link: 'https://www.instagram.com/smarnat.ai/',
  },
];

const CopyRightThree = () => {
  return (
    <div className="row">
      <div className="col-lg-4 order-lg-1 mb-20">
        <ul
          className="
              d-flex
              justify-content-center justify-content-lg-start
              footer-nav
            "
        >
          <li>
            <Link to="/privacy-and-terms-conditions">
              Privacy & Terms.
            </Link>
          </li>
          <li>
            <Link to="/contact-us">Contact Us</Link>
          </li>
        </ul>
      </div>
      <div className="col-lg-4 order-lg-3 mb-20">
        <ul
          className=" d-flex
              justify-content-center justify-content-lg-end
              social-icon"
        >
          {socialContent.map((val, i) => (
            <li key={i}>
              <a href={val.link} target="_blank" rel="noreferrer">
                <i className={`fa ${val.icon}`}></i>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="col-lg-4 order-lg-2 mb-20">
        <p className="copyright text-center">
          Copyright @{new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default CopyRightThree;

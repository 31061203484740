import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/js/bootstrap";
import "photoswipe/dist/photoswipe.css";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ScrollToTop from "./components/ScrollToTop";
import AllRoutes from "./router/AllRoutes";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "SoftwareApplication",
    name: "Smarnat",
    applicationCategory: "Productivity",
    operatingSystem: "ALL",
    description:
      "Smarnat is an intelligent chat app powered by ChatGPT, offering instant, detailed responses for limitless messaging.",
    features: [
      "ChatGPT-4 integration",
      "Intelligent responses",
      "Limitless messaging",
    ],
    keywords:
      "ai chat,ai,chat gpt,chat gpt 3,chat gpt login,chat gpt website,chat gpt,chat gtp,chat openai,chat,chatai,chatbot gpt,chatg,chatgpt login,chatgpt,gpt chat,open ai,openai chat,openai chatgpt,openai,smarnatai,smarnat",
  };

  return (
    <>
      <Helmet>
        <title>
          Smarnat: The AI-Powered Chat App | Experience Chatbot with
          ChatGPT powered app
        </title>
        <meta property="og:site_name" content="smarnat" />

        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Smarnat: ChatGPT Powered App"
        />
        <meta
          name="keywords"
          content="ai chat,ai,chat gpt,chat gpt 3,chat gpt login,chat gpt website,chat gpt,chat gtp,chat openai,chat,chatai,chatbot gpt,chatg,chatgpt login,chatgpt,gpt chat,open ai,openai chat,openai chatgpt,openai,smarnatai,smarnat"
        />
        <meta
          name="description"
          content="Smarnat, the intelligent chat app powered by ChatGPT, redefines the way you communicate with limitless messaging and instant, detailed responses."
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {/* End Seo Helmt */}

      <ScrollToTop />
      {/* End Scroll top */}

      <AllRoutes />
    </>
  );
};

export default App;

import emailjs from '@emailjs/browser';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

export const settings = {
  emailjs_serviceid: 'service_wzpajhu',
  emailjs_templateid: 'template_ic8movh',
  emailjs_publickey: 'WiCOr2af1ZB73usVQ',
};

const ContactForm = () => {
  const currentForm = useRef();
  const [serverSuccess, setServerSuccess] = useState('');
  const [serverError, setServerError] = useState('');

  // for validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    message: Yup.string().required('Please,leave us a message.'),
    email: Yup.string()
      .required('Email is required')
      .email('Entered value does not match email format'),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const {
    register,
    handleSubmit,
    formState,
    reset: resetForm,
  } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data, e) => {
    emailjs
      .sendForm(
        settings.emailjs_serviceid,
        settings.emailjs_templateid,
        currentForm.current,
        settings.emailjs_publickey
      )
      .then(
        (result) => {
          if (result.status === 200 && result.text) {
            setServerError(false);
            setServerSuccess('Email successfully sent!');
          }
        },
        (error) => {
          setServerSuccess(false);
          setServerError(
            'Something is wrong while sending the message!'
          );
        }
      )
      .finally(resetForm);
  };

  return (
    <form
      ref={currentForm}
      id="contact-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="messages"></div>
      <div className="row controls">
        <div className="col-md-12">
          <div className="input-group-meta form-group mb-60">
            <label>Name</label>
            <input
              type="text"
              placeholder="Enter your name"
              name="name"
              {...register('name')}
              className={`${errors.name ? 'is-invalid' : ''}`}
            />
            {errors.name && (
              <div className="invalid-feedback">
                {errors.name?.message}
              </div>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="input-group-meta form-group mb-60">
            <label>Email Address</label>
            <input
              placeholder="Enter Your Email"
              name="email"
              type="text"
              {...register('email')}
              className={` ${errors.email ? 'is-invalid' : ''}`}
            />
            {errors.email && (
              <div className="invalid-feedback">
                {errors.email?.message}
              </div>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="input-group-meta form-group lg mb-40">
            <label>Message</label>
            <textarea
              placeholder="Your message goes here.."
              name="message"
              type="text"
              {...register('message')}
              className={`${errors.message ? 'is-invalid' : ''}`}
            ></textarea>
            {errors.message && (
              <div className="invalid-feedback">
                {errors.message?.message}
              </div>
            )}
          </div>
        </div>
        {/* End .col */}

        <div className="col-12">
          <button type="submit" className="theme-btn-two">
            Send Message
          </button>

          {!!serverError && (
            <div className="invalid-feedback">{serverError}</div>
          )}
          {!!serverSuccess && (
            <div className="valid-feedback">{serverSuccess}</div>
          )}
        </div>
        {/* End .col */}
      </div>
    </form>
  );
};

export default ContactForm;

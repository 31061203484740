import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import useIsMobile from "../hooks/useIsMobile";

const PortfolioImages = [
  "preview-0",
  "preview-1",
  "preview-2",
  "preview-3",
  "preview-4",
  "preview-5",
  "preview-6",
];

const MobileAppPortfolio = () => {
  const { isMobile } = useIsMobile();

  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {PortfolioImages.map((val, i) => (
          <div className="item" key={i}>
            <div className="img-holder">
              <img
                style={{
                  width: !isMobile ? "75%" : "45%",
                  height: "auto",
                }}
                src={`images/assets/app-previews/${val}.jpg`}
                alt="screen"
              />
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default MobileAppPortfolio;
